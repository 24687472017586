import { formatCode } from '@lib/strings';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VpnKey from '@mui/icons-material/VpnKey';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from '../../components/LinkNext';
import { SUPPORT_EMAIL } from '../../environment';
import { useToggle } from '../../utils/hooks';
import { isEmail } from '../../utils/validation';
import { extractGraphqlErrors, get2FADevice } from './formHelpers';

const LoginForm = ({ onSubmit }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [type2fa, setType2fa] = useState('');
  const [authentication, setAuthentication] = useState();
  const [failedLoginAttempts, setFailedLoginAttempts] = useState(0);

  const errorHandler = ({ response }) => {
    const errors = extractGraphqlErrors(response.errors);
    const { type2fa } = extractInfo(response.errors);

    setType2fa(type2fa);
    Object.keys(errors).forEach(key => {
      if (key === 'authentication') {
        setAuthentication({
          type: 'manual',
          message: errors[key],
        });
      } else {
        setError(key, {
          type: 'manual',
          message: errors[key],
        });
      }
    });
  };

  const submitHandler = values => {
    setLoading(true);
    return onSubmit(values).catch(err => {
      setAuthentication();
      setLoading(false);
      errorHandler(err);
      setFailedLoginAttempts(prev => ++prev);
    });
  };

  const emailField = register('email', {
    required: "Email can't be blank",
    validate: {
      isEmail: v => isEmail(v) || 'Must be a valid email',
    },
  });

  return (
    <form onSubmit={handleSubmit(submitHandler)} aria-label="login">
      <Typography variant="h6">
        <FormattedMessage defaultMessage="Sign in" />
      </Typography>

      <Fade in={!!(authentication?.message ?? '')} timeout={300} unmountOnExit>
        <Alert
          severity="error"
          icon={<ErrorOutline color="error" />}
          sx={{
            backgroundColor: 'transparent',
            alignItems: 'center',
            color: 'error.main',
            p: 0,
            mt: 1,
          }}
        >
          {authentication?.message ?? ''}
        </Alert>
      </Fade>

      <Box mt={3} mb={1}>
        <TextField
          name={emailField.name}
          onChange={emailField.onChange}
          onBlur={emailField.onBlur}
          inputRef={emailField.ref}
          type="email"
          autoFocus
          label={intl.formatMessage({ defaultMessage: 'Email' })}
          fullWidth
          error={Boolean(errors?.email)}
          helperText={errors?.email?.message ?? ' '}
        />
      </Box>

      <Box my={1}>
        <PasswordField register={register} errors={errors} />
      </Box>

      <Collapse in={Boolean(type2fa)} unmountOnExit>
        <Box data-testid="login-mfa" my={1}>
          <MultiFactorField
            register={register}
            errors={errors}
            setValue={setValue}
            type2fa={type2fa}
          />
        </Box>
      </Collapse>

      <Box my={3}>
        <Typography variant="body2">
          <FormattedMessage
            defaultMessage="Need help with sign in? {resetPasswordLink} or {supportLink}"
            values={{
              supportLink: (
                <Link
                  href={`mailto:${SUPPORT_EMAIL}?subject=Issue%20with%20signin`}
                  color="primary"
                >
                  <FormattedMessage defaultMessage="contact support" />
                </Link>
              ),
              resetPasswordLink: (
                <Link href="/reset_password" color="primary">
                  <FormattedMessage defaultMessage="Reset password" />
                </Link>
              ),
            }}
          />
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        {failedLoginAttempts >= 2 && <SwitchRegionAlert />}
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading}
          size="large"
          endIcon={
            loading ? <CircularProgress size={15} color="inherit" /> : null
          }
        >
          <FormattedMessage defaultMessage="Sign in" />
        </Button>
      </Box>
    </form>
  );
};

const PasswordField = ({ register, errors }) => {
  const intl = useIntl();
  const [showPassword, toggleShow] = useToggle();
  const field = register('password', {
    required: "Password can't be blank",
  });

  return (
    <TextField
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      inputRef={field.ref}
      type={showPassword ? 'text' : 'password'}
      label={intl.formatMessage({ defaultMessage: 'Password' })}
      fullWidth
      error={Boolean(errors?.password)}
      helperText={errors?.password?.message ?? ' '}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShow}
              edge="end"
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const MultiFactorField = ({ register, errors, setValue, type2fa }) => {
  const intl = useIntl();
  const field = register('token', {
    maxLength: {
      value: 6,
      message: '2FA token must be 6 characters long',
    },
    minLength: {
      value: 6,
      message: '2FA token must be 6 characters long',
    },
  });

  const handleFormatCode = event => {
    setValue('token', formatCode(event.target.value), {
      shouldValidate: true,
    });
  };

  return (
    <TextField
      name={field.name}
      onBlur={field.onBlur}
      inputRef={field.ref}
      autoComplete="off"
      autoFocus
      label={intl.formatMessage({
        defaultMessage: 'Please enter your 2FA token',
      })}
      placeholder="######"
      onChange={handleFormatCode}
      fullWidth
      error={Boolean(errors?.token)}
      helperText={get(
        errors,
        'token.message',
        <span data-testid="login-mfa-help-text">
          {intl.formatMessage(
            { defaultMessage: 'Please enter the code from your {channel}' },
            { channel: get2FADevice(type2fa) },
          )}
        </span>,
      )}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <VpnKey color="primary" />
          </InputAdornment>
        ),
      }}
    />
  );
};

const SwitchRegionAlert = () => {
  const intl = useIntl();
  if (window.location.origin.includes('us')) return null;
  return (
    <Box
      py={1}
      px={2}
      mr="auto"
      display="flex"
      alignItems="center"
      bgcolor="rgba(243, 187, 0, 0.32)"
      border="1px solid #F3BB00"
      borderRadius="5px"
    >
      <Typography variant="body2">
        {intl.formatMessage({
          defaultMessage: 'Are you using the correct region?',
        })}
        &nbsp;
        <a
          href={`https://app.us.trialbee.com/login`}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: 'inherit',
          }}
        >
          {intl.formatMessage({ defaultMessage: 'Switch to US.' })}
        </a>
      </Typography>
    </Box>
  );
};

function extractInfo(graphQLErrors = []) {
  return graphQLErrors
    .map(({ extensions: { type2fa } }) => ({ type2fa }))
    .reduce((a, b) => ({ ...a, ...b }), {});
}

export default LoginForm;
