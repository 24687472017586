/**
 *
 * @description validate if string is a valid email
 *
 * @param {string} x
 *
 * @returns {boolean}
 */
export function isEmail(x) {
  return emailRegex.test(x);
}

/**
 * Regex from http://emailregex.com/
 */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
