import { gql, useLazyQuery } from '@apollo/client';
import { useAuth } from '@lib/authentication';
import { useFreshDeskDisplayStyle } from '@lib/hooks';
import { toSafeRedirect } from '@lib/routing-helpers';
import { useTracking } from '@lib/tracking';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import LoginForm from '../components/LoginForm';
import Version from '../components/Version';
import FullScreenPage from '../unauthorized/layouts/FullScreenPage';

const QUERY = gql`
  query WEB_DetermineLoginRedirect {
    studies(filter: { status: ACTIVE }) {
      nodes {
        id
        featureOptions {
          studyId
          recruitmentPortal
          consenteesPortal
          analyticsPortal
        }
      }
    }
  }
`;

function LoginPage() {
  const router = useRouter();
  const [executeQuery] = useLazyQuery(QUERY, {
    fetchPolicy: 'network-only',
  });
  const { login } = useAuth();
  const { identify, people, track } = useTracking();

  useFreshDeskDisplayStyle('none');

  useEffect(() => {
    const isUsDomain = window.location.origin.includes('us');
    const isBHVStudy = (router.query?.redirectTo ?? []).includes('BHV');

    if (isBHVStudy && !isUsDomain) {
      window.location.replace(
        toSafeRedirect(
          'https://app.us.trialbee.com',
          `/login?redirectTo=${router?.query?.redirectTo}`,
        ),
      );
    }
  });

  const getFallbackRedirect = async () => {
    const result = await executeQuery();
    const studies = result?.data?.studies?.nodes ?? [];

    return studies.length === 1
      ? `/studies/${studies[0].id}/${getEnabledFeature(studies[0])}`
      : '/studies';
  };

  const getEnabledFeature = s =>
    Object.entries(s.featureOptions).find(([k, v]) => v === true)[0];

  const performLogin = async ({ email, password, token }) => {
    const result = await login(email, password, token);

    let path;
    let redirect = router.query.redirectTo ?? '';
    if (redirect) {
      path = redirect;
    } else {
      path = await getFallbackRedirect();
    }

    identify(result.userMe.id);
    people.set({
      firstName: result.userMe.firstName,
      role: result.userMe.roles.nodes[0].role.name,
    });
    track('Login success', {
      '2fa': result.userMe.type2fa,
      redirect: redirect,
    });

    const currentHost = window.location.origin;
    router.push(toSafeRedirect(currentHost, path));
  };

  return (
    <>
      <Version
        style={{ position: 'absolute', bottom: '0.5em', right: '1em' }}
      />
      <FullScreenPage width={500}>
        <LoginForm onSubmit={performLogin} />
      </FullScreenPage>
    </>
  );
}

export default LoginPage;
