import { FormattedMessage } from 'react-intl';

export function extractGraphqlErrors(graphQLErrors) {
  if (!Array.isArray(graphQLErrors)) {
    return undefined;
  }

  return graphQLErrors
    .flatMap(({ extensions: { code, exception, ...rest } }) => {
      switch (code) {
        case 'TOTP_INVALID':
          return [
            {
              type: 'token',
              message: (
                <FormattedMessage defaultMessage="The code is invalid." />
              ),
            },
          ];
        case 'TOTP_EXPIRED':
          return [
            {
              type: 'token',
              message:
                exception.type2fa === 'DEVICE' ? (
                  <FormattedMessage defaultMessage="Please enter new code. The code expired." />
                ) : (
                  <FormattedMessage
                    defaultMessage="Please enter new code. New code sent by {channel}. Please enter the new code."
                    values={{ channel: get2FADevice(exception.type2fa) }}
                  />
                ),
            },
          ];
        case 'UNAUTHENTICATED': {
          return [
            {
              type: 'authentication',
              message: (
                <FormattedMessage
                  defaultMessage="Wrong username or password. You can try {remainingAttempts} more times before the account will be locked for {minutesBack} minutes."
                  values={rest}
                />
              ),
            },
          ];
        }
        case 'ACCOUNT_LOCKED_AWAIT_COOLDOWN':
          return [
            {
              type: 'authentication',
              message: (
                <FormattedMessage
                  defaultMessage="User account temporarily locked. Too many attempts to login with incorrect username or password. You must wait {wait} minutes before you can try again."
                  values={rest}
                />
              ),
            },
          ];
        case 'VALIDATION_ERROR':
          return (exception?.errors ?? []).map(({ dataPath, message }) => {
            if (dataPath.match(/email/)) {
              return {
                type: 'email',
                message: (
                  <FormattedMessage defaultMessage="Invalid email format" />
                ),
              };
            }
            if (dataPath.match(/password/)) {
              return {
                type: 'password',
                message: (
                  <FormattedMessage defaultMessage="Invalid password format" />
                ),
              };
            }

            return { message };
          });
        default:
          return [];
      }
    })
    .reduce((memo, { type, message }) => ({ ...memo, [type]: message }), {});
}

export const get2FADevice = (type2fa = '') =>
  deviceMap[type2fa.toLowerCase()] ?? '';

const deviceMap = {
  sms: <FormattedMessage defaultMessage="SMS" />,
  email: <FormattedMessage defaultMessage="e-mail" />,
  device: <FormattedMessage defaultMessage="Google Authenticator App" />,
};
