import { Box, Paper, useTheme } from '@mui/material';
import Image from 'next/image';

function FullScreenPage({ logo = true, width = 500, children }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        background: ({ background }) => background.fullScreenPage,
      }}
    >
      <Box
        width="100vw"
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box component={Paper} pt={6}>
          {logo && (
            <Box width={280} mx="auto">
              <Image
                alt="logo"
                src={theme.logo.fullScreenPage}
                width={280}
                height={132.75}
              />
            </Box>
          )}

          <Box mt={8} m={3} sx={{ width: { xs: '90vw', sm: width } }}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FullScreenPage;
